import React, { useEffect, useState } from "react";

const DelayedToggle = ({ children, delay, show }) => {
    let timer = null;
  
    // First, set the internal `visible` state to negate 
    // the provided `show` prop
    const [visible, setVisible] = useState(!show);
  
    // Call `setTimer` every time `show` changes, which is
    // controlled by the parent.
    useEffect(() => {
      setTimer();
    }, [show]);
  
    function setTimer() {
      // clear any existing timer
      if (timer != null) {
        clearTimeout(timer)
      }
  
      // hide after `delay` milliseconds
      timer = setTimeout(() => {
        setVisible(!visible);
        timer = null;
      }, delay);
    }
  
    // Here use a `Fade` component from Material UI library
    // to create a fade-in and -out effect.
    return visible 
      ?
        <span />
      : 
        children
      ;
  };

export default DelayedToggle;