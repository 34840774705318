import axios from "axios"

const uploadFile = async (
    e,
    setUploading,
    data,
    setData,
    fileError, 
    setFileError,
    setProgress,
    multiple = true,
) => {
    const formData = new FormData()
    if (multiple) {
        const files = e
        for (let i = 0; i < files.target.files.length; i++){
            formData.append("dem", files.target.files[i])
        }
    } else {
        formData.append("dem", e.target.files[0])
    }

    setUploading(true)

    try {
        const config = {
            onUploadProgress: progressEvent => {
              setProgress((progressEvent.loaded / progressEvent.total) * 100); 
            }
        }
        await axios.post("https://api.projecte.space/upload", formData, config).then((req, res) => {
            if (req.data.status !== "error"){
                setFileError("")
                setData(data.concat(req.data.data));
            }
            else{
                setFileError(req.data.message)
            }

            setUploading(false)
        })

    } catch (error) {
        setUploading(false);
        setFileError("An error occurred during the download");
    }
}

const deleteFile = (
    index,
    data,
    setData
) => {
    try {
        const formData = {
            data: data,
            index: index
        }
        axios.post("https://api.projecte.space/upload/delete", formData).then((req, res) => {
            console.log(req)
            if (req.status == 200){
                setData(req.data.data)
            }
            else{
                alert(req.message)
            }})
    } catch (error) {
    }
}

export {
    uploadFile,
    deleteFile
}