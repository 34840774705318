import React from "react";
import "../css/Terms.css";
import Header from "./Header";
import Footer from "./Footer";


function Terms() {
    return(
        <>
            <Header/>
            <div className="general-terms container">
                <h1>GENERAL TERMS AND CONDITIONS OF SALE</h1>
                <p>
                    These general terms and conditions of sale (hereinafter the "Terms") are applicable to the website accessible at the address www.pandascore.co (hereinafter the "Site"), operated by the company Pandascore, a simplified joint stock company with capital of 172.13 euros, whose registered office is located at 24, rue Pierre Bonnard, 92260 Fontenay-aux-Roses, registered with the Trade and Companies Register of the city of Nanterre under number 813 145 745, intra-community VAT: FR58813145745.
                </p>
                <h2>Article 1 - General information - Application of the General Terms and Condition of sale</h2>
                <p>
                    <span>1.1</span> The purpose of these Terms and Conditions is to determine the conditions under which any professional, to the exclusion of any consumer as well as any non-professional (hereinafter the "Customer") may subscribe to data and statistical analysis services applied to multiplayer video game competitions (hereinafter the "Subscription") and thus benefit from a right of access to the Pandascore database including video game data, statistics and competitions (hereinafter the "Database").
                    <br/>
                    <br/>
                    The Customer acknowledges and guarantees that he has the capacity and the required age (majority) to enter into a contract.
                    <br/>
                    <br/>
                    <span>1.2</span> The Terms and Conditions are made available to Customers on the Site, where they are directly available and accessible at any time.
                    <br/>
                    <br/>
                    The T&amp;Cs apply, without restriction or proviso, and ex officio, to any Subscription taken out by a Customer via the Site. These Terms and Conditions are the only ones applicable, to the exclusion of any others, whatever the clauses that may appear on the Customer's documents, and in particular its general conditions of purchase.TheT&amp;Cs will prevail, as applicable, over any other version or any other contradictory document.
                    <br/>
                    <br/>
                    In accordance with Article L. 441-6 of the French Commercial Code, the General Terms and Conditions of Sale constitute the sole basis of the commercial relationship between the parties, and are made available to the Customer to enable him to take out a Subscription with Pandascore. The Customer is required to read them before taking out the Subscription. The choice and the purchase of a Subscription is the sole responsibility of the Customer, who guarantees he has read the documentation presenting the Database, the Subscriptions, the processed data and their typology according to each Subscription.
                    <br/>
                    <br/>
                    <span>1.3</span> Taking out a Subscription implies that the Customer has read and accepted, in advance and expressly, the Terms and Conditions of Use of the Site, which may be saved and/or printed, by clicking on the confirmation button provided for this purpose during the subscription procedure. This subscription implies the acceptance, without restriction or provision of all the T&amp;Cs.
                    <br/>
                    <br/>
                    No Subscription may be taken out unless the Terms and Conditions of Use are accepted in advance and without provisos by the Customer.
                    <br/>
                    <br/>
                    <span>1.4</span> The detailed description of the different Subscriptions provided by Pandascore is available on the Site. The information on the Site is subject to review at any time and without notice. Pandascore is entitled to make any modifications it deems fit.
                    <br/>
                    <br/>
                    <span>1.5</span> Pandascore reserves the right to modify the T&amp;Cs, at any time and without notice. Modifications to the T&amp;Cs are enforceable against Customers from the moment they are posted on the Site. The modified T&amp;Cs apply to allSubscriptions concluded after the posting date of the T&amp;Cs, and Subscriptions in progress from the first day of the month after their posting.Customers will be notified of the change of Terms and shall be required to expressly accept this change. In the event of the Customer’s refusal to accept the new Terms and Conditions, his Subscriptions will be terminated automatically on the last day of the month the new T&amp;Cs were posted.
                    <br/>
                    <br/>
                    <span>1.6</span> In accordance with the provisions of Article 1126 of the French Civil Code, the Customer expressly accepts the use of electronic mail for the communication of any information necessary or requested for the conclusion of the Contract, or that which is sent during its performance.
                    <br/>
                    <br/>
                </p>
                <h2>Article 2 - Content of Subscriptions</h2>
                <p>
                    Subscriptions allow access to the Database according to four different formulas and containing the following services:
                    <br/>
                    <br/>
                    <span>2.1</span> The Fixture Subscription:
                    <br/>
                    <br/>
                    <ul>
                        <li>Static data: names of players, elements and basic information for all games;</li>
                        <li>The calendar of past and future events;</li>
                        <li>Pre-match data: information about tournaments, teams, players;</li>
                        <li>A maximum rate of four thousand (4k) requests per hour.</li>
                    </ul>
                    <span>2.2</span> The Historical Data Subscription:
                    <br/>
                    <br/>
                    <ul>
                        <li>All services offered by the Fixture Subscription;</li>
                        <li>Post-match data: results and details by match;</li>
                        <li>Historical statistics: statistics filtered by players, by team;</li>
                        <li>A maximum rate of four thousand (4k) requests per hour.</li>
                    </ul>
                    <span>2.3</span> The Live Basic Subscription:
                    <br/>
                    <br/>
                    <ul>
                        <li>All services offered by Fixture and Historical Data Subscriptions;</li>
                        <li>Real-time Programming Interface (API)</li>
                        <li>All available statistics;</li>
                        <li>Detailed event data for each match;</li>
                        <li>A maximum rate of four thousand (4k) requests per hour.</li>
                    </ul>
                    <span>2.4</span> The Live Pro Subscription:
                    <br/>
                    <br/>
                    <ul>
                        <li>All services offered by Fixture and Historical Data Subscriptions;</li>
                        <li>Real-time Programming Interface (API)</li>
                        <li>All available statistics;</li>
                        <li>Detailed event data for each match;</li>
                        <li>A maximum rate of four thousand (4k) requests per hour.</li>
                    </ul>
                    <span>2.5</span> The list of available data is detailed on the Site under the Coverage tab. Each list is specifically associated with a game. For each game, the available data will be identical between the different leagues supported by Pandascore.
                </p>
                <h2>Article 3 - Taking out a Subscriptions</h2>
                <p>
                    <span>3.1</span> A Subscription is taken out exclusively online, via the Site. To take out a Subscription, the Customer must first create an Account on the Site, in accordance with the Terms of Use.
                    <br/>
                    <br/>
                    To take out a Subscription, other than the Fixture, the Customer must choose the desired Subscription in thePricing section of his Account, subject to the availability of the Subscription for the game in question.Subscriptions other than Fixture are not free.
                    <br/>
                    <br/>
                    Each Account may be associated with four different Subscriptions corresponding to service levels linked to a price level. Each supported game may be associated with a different subscription. Upon creation of the Account, each game is by default set to the "Fixture" subscription.
                    <br/>
                    <br/>
                    The Customer must enter the name of his company, the url address of his company and his credit card number, before confirming payment for the new Subscription selected.
                    <br/>
                    <br/>
                    <span>3.2</span> The Customer indicates the selected Subscriptions, then clicks on the "subscribe" button.
                    <br/>
                    <br/>
                    A summary is shown for the order, including the selected Subscriptions and the amount of the corresponding bank debit.
                    <br/>
                    <br/>
                    Before validating a Subscription order, it is up to the Customer to verify the accuracy of his order and all its elements, including the total price and, if necessary, to identify and correct errors or modify his order if necessary. The Customer may then definitively confirm his order, which implies:
                    <br/>
                    <br/>
                    <ul>
                        <li>Unrestricted and unreserved acceptance of the full terms and conditions of use of the Website and the T&amp;Cs by clicking on the confirmation box provided for this purpose. No Order will be accepted if the Terms and Conditions are not accepted by Customer.</li>
                        <li>Authorising Pandascore to debit his credit card through the payment provider.</li>
                    </ul>
                    <br/>
                    <br/>
                    Any validation constitutes acceptance of the prices and descriptions of the services included in the Subscriptions as described on the Site. The user authorised by the Customer to confirm a Subscription requires the Customer to perform the payments and declares that he is duly authorised for this purpose.
                    <br/>
                    <br/>
                    <span>3.3</span> The taking out of the new Subscription is deemed definitive and does not take effect until the acceptance of the monthly payment by Pandascore’s payment provider.
                    <br/>
                    <br/>
                    Confirmation of the Subscription is confirmed to the Customer by sending an email to the address indicated by the latter when creating the Account. It is the Customer's responsibility to check the items indicated in the confirmation email and, if necessary, to notify Pandascore of any errors as soon as possible.
                    <br/>
                    <br/>
                    In the event that the Customer does not receive confirmation e-mail, it is his responsibility to contact Pandascore as soon as possible.
                    <br/>
                    <br/>
                    Pandascore may not be held liable for any direct or indirect damage resulting from errors or lack of confirmation that were not reported by the Customer.
                    <br/>
                    <br/>
                    <span>3.4</span> Pandascore reserves the right to refuse any Subscription that appears to be contentious or fraudulent, and any Subscription by a Customer with whom there is a dispute of any kind, past or current, including a dispute concerning the payment of a previous Subscription.
                    <br/>
                    <br/>
                    <span>3.5</span> The Account is linked to apersonal and strictly confidential token. This token may not be shared with third parties. Failure to respect the strictly personal and confidential nature of the token will lead to the termination of the Subscription and the closing of the Account by Pandascore. The token is unique to each Account and can identify requests by linking them to the Account. Each request via the programming interface must include the token. In the event of the use of a token by an unauthorised third party, Pandascore reserves the right to unilaterally terminate the Subscription and close the Customer's Account, without the latter being able to request any compensation and without prejudice to any damages that Pandascore may claim from the Customer.
                    <br/>
                    <br/>
                    <span>3.6</span> The computerised records, stored in the computer systems of Pandascore under reasonable conditions of security, will be considered proof of the communications, orders and payments made between the parties. The archiving of subscription forms and invoices iscarried out on a reliable and durable support that can be produced as evidence.
                </p>
                <h2>Article 4 - Financial conditions</h2>
                <p>
                    <span>4.1</span> The price which the Customer is required to pay, in return for the provision of the services corresponding to a Subscription, varies depending on the Subscriptions taken out by the Customer and is detailed on the Site.
                    <br/>
                    <br/>
                    The price refers to a Monthly Subscription and is defined in the Pricing section of the Customer's Account. The price is excluding taxes and is payable in euros (€).
                    <br/>
                    <br/>
                    Access to the data corresponding to the subscription taken out by the Customer becomes effective from the first payment taken into account.
                    <br/>
                    <br/>
                    No discount is given.
                    <br/>
                    <br/>
                    Payment is made monthly only by credit card via the Pandascore payment provider.
                    <br/>
                    <br/>
                    Pandascore will email the corresponding invoice to the Customer.
                    <br/>
                    <br/>
                    <span>4.2</span> At no time shall Pandascore collect or have access to the Customer's information relating to its bank details and the payment method used to pay the Subscription.
                    <br/>
                    <br/>
                    <span>4.3</span> In the event of the Customer justifiably disputing one or more items of the invoice, the obligation to pay the sum in dispute will be suspended. The Customer will send Pandascore, before the contractual due date of the invoice, a note justifying his position. In the event of the claim being upheld,Pandascore will draw up a credit note cancelling the disputed invoice and a new invoice for the items of the undisputed part.
                    <br/>
                    <br/>
                    <span>4.4</span> In the event of default or late payment and payment of sums due by the Customer after the agreed deadline, Pandascore will be entitled to claim late penalties equal to three (3) times the statutory interest rate automatically and ex officio from the day after the due date of the invoice, without any formality or prior notice and until full payment of the sums due. In addition to penalties for late payment, any late payment gives rise to the payment of a lump sum of forty euros (€40) for recovery costs. When the collection costs incurred exceed this amount,Pandascoremay request additional compensation subject to supporting documentation. This compensation is without prejudice to the payment of damages and interest in the event of a loss.
                    <br/>
                    <br/>
                    <span>4.5</span> In case of non-compliance with the terms of payment above, Pandascore also reserves the right to cancel the provision of services included in the Subscriptions taken out by the Customer or to suspend the performance of its obligations, until full payment of the unpaid invoice, without this non-execution being deemed attributable to it.
                    <br/>
                    <br/>
                    No compensation may be made by the Customer between any late penalties or suspension in the provision of services included in the Subscriptions or non-compliance with the Contract, on the one hand, and the amounts due by the Customer to Pandascore in respect of subscriptions, on the other hand.
                </p>
                <h2>Article 5 - Duration - Termination</h2>
                <p>
                    <span>5.1</span> The T&amp;Cs enter into force on the day of subscription for a subscription which is concluded for a fixed period of one (1) month. This contract is automatically renewed by monthly period.The Customer may cancel his subscription at any time. In this case, the termination will take effect on the last day of the month in which the termination took place.
                    <br/>
                    <br/>
                    Payment of a Subscription is monthly, giving access during a calendar month to the services included in the Subscription.
                    <br/>
                    <br/>
                    <span>5.2</span> The Customer may choose to change the Subscription at any time. In the case of adowngrade of a Subscription to a lower level, the Subscription previously taken out will remain active until the end of the month. In the case of taking out a higher-level Subscription, the new Subscription will take effect upon confirmation of the payment.
                    <br/>
                    <br/>
                    The downgrade or upgrade of the Subscription is to be understood according to the following hierarchy: Fixture &lt; Historical Data &lt; Live Basic &lt; Live Pro.
                    <br/>
                    <br/>
                    <span>5.3</span> In the event of early termination for any reason whatsoever, the termination will not end the Customer's obligations, in particular those related to the payment of services rendered by Pandascore and remaining due on the date of termination. All sums already paid will be retained, and invoices unpaid on the day of the termination will still be payable to Pandascore.
                </p>
                <h2>Article 6 - Terms of provision of the services included in the Subscription Access to the Database</h2>
                <p>
                    <span>6.1</span> In accordance with the Terms of Use of the Website to which the Customer expressly adheres, the Customer is solely responsible for his use of the Site, all its features and all of its data and information, any other action on his part on the Site and his Subscription.
                    <br/>
                    <br/>
                    The Customer uses the Site and the Subscriptions under his exclusive responsibility. Pandascore shall under no circumstances be liable to any person, in particular to the Customer, for any direct, indirect, incidental, special or consequential damages, including, without limitation, viruses, computer defects or malfunctions, loss or the alteration of customers or profits, income, opportunity, data, errors, omissions, losses or any delays, loss of profits, loss of image, expenses, etc., arising from time to time, in relation or as a result of the use of the Site and / or Subscriptions by the Customer.
                    <br/>
                    <br/>
                    The Customer guarantees Pandascore that its activity performed on and via the Site will not be contrary to any law or regulation in force, Pandascore may not be held liable for any activity of the Customer on or via the Site and / or any direct or indirect damage arising from the Customer's use of the Site and all of its data. The Customer holds Pandascore safe against any claim or action by third parties in this respect.
                    <br/>
                    <br/>
                    Pandascore reserves the right to terminate the Subscription and terminate the Customer's account if the latter's activities are contrary to the laws and regulations of (i) the country in which the Customer is located and / or (ii) the country in which the Customer markets its services.
                    <br/>
                    <br/>
                    The Customer undertakes to take all reasonable steps to protect the Database against unauthorised access, copying or use. As the Customer is solely responsible for access to the Account and the Database, it is his responsibility to take all necessary measures to ensure this access is strictly reserved to the authorised user within it.
                    <br/>
                    <br/>
                    Pandascore reserves the right to take legal action against any offending Customer to obtain compensation for any damage it has suffered.
                    <br/>
                    <br/>
                    <span>6.2</span> By taking out a Subscription and paying its price, Pandascore authorises the Customer to access its Database and to use the data contained therein in accordance with this Subscription.
                    <br/>
                    <br/>
                    This Database is and remains the property of Pandascore, which holds both the copyright and the sui generis right of the database producer.
                    <br/>
                    <br/>
                    Pandascore assigns to the Customer, on a non-exclusive and non-transferable basis, the sufficient and necessary rights for the Customer to freely use the contents of this Database as part of its Subscription and as part of its activity to feed its own statistics, particularly for the purposes of study and analysis.
                    <br/>
                    <br/>
                    <span>6.3</span> Pandascore also authorises the Customer to reproduce the data to which it has had access through Subscription but only on its website, for information purposes, and to the exclusion of any marketing of said data. This restriction does not prevent the Customer from charging its own customers for access to his site where the data is reproduced, as long as the data reproduced by the Customer, and which it has had access to through its Subscription, is not the true purpose of the payment made to the Customer by its own customers.
                    <br/>
                    <br/>
                    For this purpose, it is expressly forbidden for the Customer to provide direct access from its own site, to the database, the programming interface of Pandascore, or to any part of the pandascore.co site, in particular by directly providing the url addresses of the Pandascore application to its customers.The customer must reproduceon its own site the data to which it has access and that it wants to be able to disseminate, in accordance with the limitations of the T&amp;Cs.
                    <br/>
                    <br/>
                    <span>6.4</span> The Customer is not in any way authorised to distribute, communicate or reproduce the raw data directly from the Database for commercial purposes or for consideration.In any event, none of the raw data obtained from the Database or obtainedthrough the use of the Site may be transferred as is, for free or for consideration, to any third party.
                    <br/>
                    <br/>
                    Only the results of the Customer’s work based directly or indirectly on the data contained in the Database and which has been the subject of specific processing by the Customer may, where appropriate, be marketed by it.
                    <br/>
                    <br/>
                    In any event, even in the case of internal use or results the Customer’s work based directly or indirectly on the data contained in the Database, the Customer shall affix to any document and any medium reproducing all or part of the Pandascore data, the source as follows: "Source: Pandascore".
                    <br/>
                    <br/>
                    <span>6.5</span> Pandascore reserves the right to terminate the Subscription and close the Customer's account if it does not comply with the prohibitions set out in Articles 6.3 and 6.4.
                    <br/>
                    <br/>
                    In the event of termination of the contractual relationship between the parties, for any reason whatsoever, the Customer will immediately cease all use of the Pandascore database as well as all the data it has had access to through its Subscription.
                </p>
                <h2>Article 7 - Penalty clause</h2>
                <p>
                    Without prejudice to the possibility for Pandascore to terminate the Customer's subscription without notice in the cases permitted by these Terms and Conditions, the Customer shall immediately be liable, without any reminder, to Pandascore for the following amounts
                    <ul>
                        <li>five hundred euros (€500) for each proven use of a token by an unauthorised third party;</li>
                        <li>five hundred euros (€500) per day during which the Customer provides its own customers with direct access to the database, the programming interface or any part of the Pandascore website;</li>
                        <li>five hundred euros (€500) for each proven resale of the raw Pandascore data by the Customer to one of its own customers;</li>
                        <li>five hundred euros (€500) per day during which the Customer continues to use the Pandascore database without any right or title after the termination of its Subscription.</li>
                    </ul>
                </p>
                <h2>Article 8 - Limitation of liability</h2>
                <p>
                    <span>8.1</span> Pandascore will do all possible to ensure that the Site functions properly at all times and to provide the Customer with access to its Account and Subscription. Pandascore is bound only by an obligation of means.Interruptions may occur for reasons of maintenance or updating, in case of emergency repairs or in the event of telecommunication systems failure or other technical problems beyond the control of Pandascore.
                    <br/>
                    <br/>
                    Pandascore may not be held liable in the event of force majeure, computer attack (piracy, hacking, etc.), failure (or malfunction) of hardware (including computer), telecommunications and computer networks (including internet) or electricity, etc., especially when these events result in a delay, a failure to perform or an interruption of access to the Site, the Account or the Subscription. In general, Pandascore may not be held liable for any failure that is not its fault.
                    <br/>
                    <br/>
                    Pandascore may temporarily suspend or limit access to the Site, Accounts, Subscriptions and the Database immediately and without prior notice, where Pandascore believes, at its sole discretion, that the Site and/or the Accounts are subject to, or will be subject to, a serious threat to the security of the data and/or its system (e.g. security breach that may for example come from the publisher of the antivirus or operating system, ...).
                    <br/>
                    <br/>
                    For reasons related in particular to the security of the programming interface, stability and interoperability, Pandascore may be required to automatically update, preload, create new versions of Subscriptions or to improve them.
                    <br/>
                    <br/>
                    The T&amp;C gives the Customer no right to require updates, new versions or other improvements to the Subscriptions. Pandascore decides to provide such updates or enhancements at its sole discretion.
                    <br/>
                    <br/>
                    The Customer may contact Pandascore for any question relating to the Site through the email address "hello@pandascore.co".
                    <br/>
                    <br/>
                    <span>8.2</span> Pandascore does not guarantee the accuracy or exhaustiveness of any data or information accessible from the Site or through the Subscriptions, nor uninterrupted access to it, and any express guarantees, implicit or legal, including any guarantee of capacity or fitness for a particular purpose, are expressly excluded.
                    <br/>
                    <br/>
                    In particular, Pandascore does not guarantee:
                    <ul>
                        <li>the accuracy, exhaustiveness and/or up to date nature of the processed data and any information accessible via the Site and/or Subscriptions and/or the Database;</li>
                        <li>the instant updating of processed data and all information accessible via the Site and / or Subscriptions and or the Database, the Customer expressly acknowledging and agreeing that the posting of such updates may, in particular for technical reasons, take some time;</li>
                        <li>the fitness and/or adequacy of the processed data and any information accessible via the Site and/or the Subscriptions and/or the Database for a specific use, or the data sought by the Customer;</li>
                        <li>the quality or usefulness of the processed data and any information accessible via the Site and/or the Subscriptions and/or the Database, in particular with a view to betting on a match;</li>
                        <li>the fitness and/or adequacy of the processed data and any information accessible via the Site and or the Subscriptions and/or the Database to the results of a match on which the Client has bet;</li>
                        <li>the absence of technical difficulties specific to the Internet, including slowdowns in access to the Site or latency and updating of information and data.</li>
                    </ul>
                    In any event, Pandascore will not be liable under any circumstances for:
                    <ul>
                        <li>any information or data provided by the Client, the latter declaring and guaranteeing that it does not infringe any rights of third parties and complies with all applicable legal and regulatory provisions;</li>
                        <li>the choice of Subscriptions ordered by the Customer;</li>
                        <li>the adequacy of its subscription offer to the real needs of the Clients;</li>
                        <li>any damage whatsoever resulting directly or indirectly from the use of the processed data and all information accessible via the Site and/or Subscriptions by the Customer and, in particular, any loss including financial loss of profits , income, odds, any missed prize, etc., in the context of bets based on said data and information;</li>
                        <li>financial positions that have been taken by Clients based on data provided by Pandascore;</li>
                        <li>any non-performance of Pandascore resulting from a case of force majeure.</li>
                    </ul>
                    <span>8.3</span> Pandascore's liability may be incurred only in the case of proven negligence or gross negligence and is limited to direct and personal injury, to the exclusion of any indirect loss of any kind whatsoever.
                    <br/>
                    <br/>
                    In any event, and under any circumstances, in the event that Pandascore's liability is incurred, Pandascore's total liability for all claims or damages may not exceed the amount of the price actually paid by the Customer in respect of the payment  in the month during which the damage occurred, even if Pandascore was informed of the possibility of such a claim or such damage.
                </p>
                <h2>Article 9 - Miscellaneous</h2>
                <p>
                    <span>9.1</span> In the event of any provision of the T&amp;Cs being considered null, illegal or unenforceable by a competent court or by an authority having jurisdiction to do so, or in application of a law or regulation in force, the contractual stipulation concerned will be removed withoutaffecting the validity or the enforceability of the other stipulations of the T&amp;Cs. The parties will consult and negotiate in good faith in an attempt to find ways to implement the purpose of this stipulation.
                    <br/>
                    <br/>
                    <span>9.2</span> The fact that one of the parties does not exercise a right or enforce the performance of an obligation incumbent on the other under the T&amp;Cs does not means it waives this right or performance of this obligation, and does not exempt the defaulting party from the future fulfilment of its obligations under the Contract in accordance with it.
                </p>
                <h2>Article 10 - Dispute Resolution</h2>
                <p>
                    <span>10.1</span> The T&amp;Cs and the operations resulting from it are subject to French law. The original Terms and Conditions are written in French. In the event that they are translated into one or more languages, only the French text will prevail in the event of a dispute.
                    <br/>
                    <br/>
                    <span>10.2</span> In the absence of an amicable agreement, any claim or litigation arising from the validity, interpretation or execution of the Terms and Conditions as well as documents related to the Contract shall be submitted to the competent courts of the Paris Court of Appeal, under the conditions of ordinary law.
                    <br/>
                    <br/>
                    <span>10.3</span> In the absence of an amicable agreement, any claim or litigation arising from the validity, interpretation or execution of the Terms and Conditions as well as documents related to the Contract shall be submitted to the competent courts of the Paris Court of Appeal, under the conditions of ordinary law.
                </p>
            </div>
            <Footer/>
        </>
    );
}

export default Terms