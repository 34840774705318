import axios from "axios"

const FormSend = (
    email,
    demo,
    social,
    setSend,
    setFormError
    ) => {
    const formData = {
        email: email,
        demo: demo,
        social:social
    };
    try{
        axios.post("https://api.projecte.space/uploadForm", formData).then((req, res) => {
            if (req.status == 200){
                setSend(true);
            }
            if (req.status == 520) {
                setSend(true);
                setFormError(req.data.message)
            }
        })
    } catch (error) {
        setSend(true);
        setFormError("An error occurred while sending, please try again later.")
    }
}

export default FormSend