import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import "../scripts/Animation"
import "../css/Home.css"

function HomePage() {

    return (
        <>
            <Header/>
            <div className="homePages">
                <canvas id="canvasOne" width={800} height={500}></canvas>
                <div className="container">
                    <h1>Your ESports ticket is here</h1>
                    <h2>Artificial intelligence-based products that enhance your skill</h2>
                    <div className="btn-area">
                        <a className="business-btn" href="/business">Join as a business
                            <svg xmlns="http://www.w3.org/2000/svg" width="19" height="10" viewBox="0 0 19 10" fill="none"><script xmlns="" type="text/javascript" charset="utf-8" id="zm-extension"/>
                                <path d="M1 5H18" stroke="#16141A" stroke-linecap="round"/><path d="M15 2L18 5L15 8" stroke="#16141A" stroke-linecap="round"/>
                            </svg>
                        </a>
                        <a className="player-btn" href="/players">Join as a player
                            <svg xmlns="http://www.w3.org/2000/svg" width="19" height="10" viewBox="0 0 19 10" fill="none"><script xmlns="" type="text/javascript" charset="utf-8" id="zm-extension"/>
                                <path d="M1 5H18" stroke="#FFF" stroke-linecap="round"/><path d="M15 2L18 5L15 8" stroke="#FFF" stroke-linecap="round"/>
                            </svg>
                        </a>
                    </div>
                </div>
            </div>
            <Footer/>
        </>
    );
    
}

export default HomePage