import React, {useState} from "react";
import Logo from "../images/Logo.png";

function Header() {
    const [toggler, setToggler] = useState(false);

    return (
        <header>
            <div className="header-container">
                <div className="items">
                    <div className="logo">
                        <a href="/">Project E
                            <img src={Logo} alt="logo"></img>
                        </a>
                    </div>
                    <button class="navbar-toggler" type="button" aria-expanded={toggler ? "true" : "false"} onClick={() => {setToggler(!toggler)}}>
						<span class="icon-bar"></span>
						<span class="icon-bar"></span>
						<span class="icon-bar"></span>
					</button>
                    <nav role="navigation" className="nav-menu" aria-expanded={toggler ? "true" : "false"}>
                        <a href="/business" className="nav-item">For business</a>
                        <a href="/players" className="nav-item">For players</a>
                        <a href="/about" className="nav-item">About</a>
                        <div className="login-area">
                            <a href="/" className="login-btn nav-item">Login</a>
                        </div>
                        <div className="signUp-area">
                            <a href="/" className="signUp-btn nav-item">Sign up</a>
                        </div>
                    </nav>
                </div>
            </div>
        </header>
    );
}
export default Header