import React, { useEffect, useState } from "react";
import { uploadFile, deleteFile } from "../scripts/uploadFile";
import Header from "./Header";
import Footer from "./Footer";
import FormSend from "../scripts/FormSend";
import { ReactComponent as Upload } from "../images/icon/upload.svg";
import { ReactComponent as Cross } from "../images/icon/cross.svg";
import "../scripts/AnimationGreen";
import "../css/Business.css";
import DelayedToggle from "./DelayedToggle";

function BusinessPage() {
    
    const [uploading, setUploading] = useState(false);
    const [send, setSend] = useState(false);
    const [data, setData] = useState([]);
    const [fileError, setFileError] = useState("");
    const [formError, setFormError] = useState("");
    const [progress, setProgress] = useState(0);
    
    let emailRef = React.createRef();  
    let socialRef = React.createRef();  

    /*const onDragOver = (e) => {
        let event = e;
        event.stopPropagation();
        event.preventDefault();
        }
    
    const onDragEnter = (e) => {
        let event = e;
        event.stopPropagation();
      }
    
    const onFileDrop = (e) => {
        let event = e;
        event.stopPropagation();
        uploadFile(e, "uploads", setUploading, data, setData);
        debugger;
      }*/

    const handleClick = e => {  
        e.preventDefault();  
        if (emailRef.current.value == "" && socialRef.current.value == "" && data.length == 0){
            alert("Please fill in all fields")
            return
        }
        if (emailRef.current.value == ""){
            alert("Please enter your e-mail");
            return;
        }
        if (data.length == 0){
            alert("Please upload your demo files");  
            return;
        }
        if (socialRef.current.value == ""){
            alert("Please specify your social network"); 
            return;
        }
        FormSend(emailRef.current.value, data, socialRef.current.value, setSend, setFormError);
    };  

    return(
        <>
            <Header/>
            <div className="businessPage">
                <canvas id="canvasCenterGreen" width={800} height={500}></canvas>
                <div className="entryForm">
                    {send ?
                        formError === "" ?
                            <div className='Success'>
                                <p>Data processing is going on.<br></br>The result will be sent to the specified email.</p>
                            </div>
                        :
                            <div className='Success'>
                                <p>{formError}</p>
                            </div>
                    :
                        <form onSubmit={handleClick}>
                            <h1>Fill out the fields below</h1>
                            <div className="email area">
                                <p className="title">Email</p>
                                <input type="email" ref={emailRef} required></input>
                            </div>
                            <div className="social area">
                                <p className="title">Link to social network</p>
                                <input type="url" ref={socialRef}></input>
                            </div>
                            <div className="upload area">
                                    {! uploading ?
                                        <>
                                            <label id="upload-container">
                                                <Upload/>
                                                <p>Upload</p>
                                                <input id="file-input" type="file" accept="application/unknown" multiple onChange={e => {uploadFile(e, setUploading, data, setData, fileError, setFileError, setProgress)}}></input>
                                            </label>
                                        </>
                                    :
                                        <>
                                            <label id="upload-container" style={{background:`linear-gradient(90deg, var(--green) ${progress}%, #FFFFFF ${progress}%)`}}>
                                                <Upload/>
                                                <p>Downloading...</p>
                                                <img src={require("../images/Load.gif")} alt="Loading..."/>
                                            </label>
                                        </>
                                    }
                            </div>
                            {fileError !== "" && !uploading ?
                                <p className="message">{fileError}</p>
                            :
                                <>
                                </>
                            }
                            {
                                data.length != 0 && fileError == "" && !uploading ?
                                    <DelayedToggle 
                                        delay={3000}
                                        show={true}
                                        children={<p className="message">File .dem uploaded successfully</p>}
                                    />
                                :
                                    <>
                                    </>
                            }
                            {
                                data.length != 0 ?
                                    <div className="slider area">
                                        <div className="slider-area">
                                            {data.map((file, index) => (
                                                <div key={index} className="slider-item">
                                                    <div className="item-img">
                                                        <div className="cross" onClick={() => {deleteFile(index, data, setData)}}>
                                                            <Cross/>
                                                        </div>
                                                    </div>
                                                    <p className="item-fileName">{file.name}</p>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                :
                                <>
                                </>
                            }
                            <div className="btn">
                                <button className="SendButton" type="sumbit">
                                    <p>Send</p>
                                </button>
                            </div>
                        </form>
                    }
                </div>
            </div>
            <Footer/>
        </>
    )
}

export default BusinessPage