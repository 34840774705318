import React, { useEffect, useState } from "react";
import { ReactComponent as SteamIco } from "../images/icon/steam.svg";
import { ReactComponent as FaceitIco } from "../images/icon/faceit.svg";
import Header from "./Header";
import Footer from "./Footer";
import "../scripts/AnimationPurple";
import axios from "axios";
import "../css/Players.css";


function PlayerPage(){

    const [steamUser, setSteamUser] = useState(false);
    const [faceitUser, setFaceitUser] = useState(false);

    useEffect(() => {
        if (read_cookie("steamCookies") != null){
            setSteamUser(true);
        }
        else {
            try{
                axios.get("https://api.projecte.space/steam", {
                    credentials: "include",
                    headers: {
                      "content-type": "application/json",
                    },
                })
                .then(res => {
                    if (res.data.steamCookies) {
                        bake_cookie("steamCookies", res.data.steamCookies.session)
                        setSteamUser(true);
                    }
                })
            } catch (error) {
    
            }
        }
        if (read_cookie("faceitCookies") != null){
            setFaceitUser(true);
        }
        else {
            try{
                axios.get("https://api.projecte.space/faceit", {
                    credentials: "include",
                    headers: {
                        "content-type": "application/json",
                    },
                })
                .then(res => {
                    if (res.data.faceitCookies) {
                        bake_cookie("faceitCookies", res.data.faceitCookies.session)
                        setFaceitUser(true);
                    }
                })
            } catch (error) {
    
            }
        }
    }, []);

    function read_cookie(name) {
        var result = document.cookie.match(new RegExp(name + '=([^;]+)'));
        result && (result = JSON.parse(result[1]));
        return result;
    }

    function bake_cookie(name, value) {
        var cookie = [name, '=', JSON.stringify(value), '; domain=', /*window.location.host.toString()*/ "", '; path=/;'].join('');
        document.cookie = cookie;
    }

    return (
        <>
            <Header/>
            <div className="playerPage">
                <canvas id="canvasCenterPurple" width={800} height={500}></canvas>
                <div className="entryForm">
                    <h1>To evaluate your skills, log in conveniently</h1>
                    <div className='Registration'>
                        {steamUser ? 
                            <div className='Success'>
                                <p>Steam is authorized</p>
                            </div>
                        :
                            <div className="btn" onClick={() => (window.location.href = "http://api.projecte.space/steam/steam_authenticate")}>
                                <SteamIco/>
                                <a>Sign in through Steam</a>
                            </div>
                        }
                        {faceitUser ?
                            <div className='Success'>
                                <p>faceit is authorized</p>
                            </div>
                        :
                            <div className="btn" onClick={() => (window.location.href = "http://api.projecte.space/faceit/faceit_authenticate")}>
                                <FaceitIco/>
                                <a>Sign in through FACEIT</a>
                            </div>
                        }
                    </div>
                </div>
            </div>
            <Footer/>
        </>
    )
}

export default PlayerPage