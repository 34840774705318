import React from 'react';
import ReactDOM from 'react-dom/client';
import './css/index.css';
import { BrowserRouter as Router, Route, Routes} from "react-router-dom";

import Privacy from './components/Privacy';
import Terms from "./components/Terms";
import HomePage from "./components/Home";
import About from "./components/About"
import PlayerPage from './components/Players';
import BusinessPage from './components/Business';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
		<Router>
			<Routes>
				<Route exact path="/" element={<HomePage/>}/>	
				<Route path="/terms-and-condition" element={<Terms/>}/>	
				<Route path="/privacy" element={<Privacy/>}/>	
				<Route path='/about' element={<About/>}/>
				<Route path='/players' element={<PlayerPage/>}/>
				<Route path='/business' element={<BusinessPage/>}/>
				<Route path='*' element={<HomePage/>}/>
			</Routes>
		</Router>
);