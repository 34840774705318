import React from "react";
import { ReactComponent as Twitter} from "../images/icon/twitter.svg"
import { ReactComponent as Facebook} from "../images/icon/facebook.svg"
import { ReactComponent as Linkedin} from "../images/icon/linkedin.svg"

function Footer() {
    return (
        <footer>
            <div className="rs">
                <div className="rs-area">
                    <div className="footer-logo">
                        <a href="/">Project E</a>
                    </div>
                    <div className="rs-list">
                        <a className="rs-item" href="/">
                            <Twitter/>
                        </a>
                        <a className="rs-item" href="https://www.facebook.com/ProjectE.space">
                            <Facebook/>
                        </a>
                        <a className="rs-item" href="/">
                            <Linkedin/>
                        </a>
                    </div>
                    <div className="copyright">
                        <p>Project E&copy; 2022. All rights reserved.</p>
                    </div>
                </div>
            </div>
            <div className="footer-nav-item">
                <div className="footer-nav-list">
                    <a href="/" className="footer-nav-list-item">Home</a>
                    <a href="/business" className="footer-nav-list-item">For business</a>
                    <a href="/players" className="footer-nav-list-item">For players</a>
                    <a href="/" className="footer-nav-list-item">Stats</a>
                    <a href="/about" className="footer-nav-list-item">About</a>
                    <div className="divider"></div>
                    <a href="/privacy" className="footer-nav-list-item">Privacy</a>
                    <a href="/terms-and-condition" className="footer-nav-list-item">Terms and conditions</a>
                </div>
            </div>
            <div className="footer-nav-item contacts">
                <p className="footer-nav-list-item default">Contact:</p>
                <a className="footer-nav-list-item" href="mailto:hello@projecte.space">hello@projecte.space</a>
                <p className="footer-nav-list-item default">Nicosia - Republic of Cyprus</p>
            </div>
            <div className="footer-nav-item">
                <div className="footer-nav-list">
                    <p className="footer-nav-list-item default">For sales inquiries:</p>
                    <a className="footer-nav-list-item" href="mailto:">sales@projecte.space</a>
                </div>
            </div>
        </footer>
    );
}

export default Footer