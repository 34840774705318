import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import "../css/About.css";
import { ReactComponent as Gamepad } from "../images/icon/About/gamepad.svg";
import { ReactComponent as Plus } from "../images/icon/About/plus.svg";
import { ReactComponent as Human } from "../images/icon/About/human.svg";
import { ReactComponent as Equally } from "../images/icon/About/equally.svg";
import { ReactComponent as Dollar } from "../images/icon/About/dollar.svg";
import { ReactComponent as Data } from "../images/icon/About/data.svg";


function About() {
    return (
        <>
            <Header/>
            <div className="about-section">
                <div className="container">
                    <h1>About</h1>
                    <div className="svg-section">
                        <div className="human">
                            <Human/>
                        </div>
                        <div className="plus">
                            <Plus/>
                        </div>
                        <div className="gamepad">
                            <Gamepad/>
                        </div>
                        <div className="plus">
                            <Plus/>
                        </div>
                        <div className="data">
                            <Data/>
                        </div>
                        <div className="equally">
                            <Equally/>
                        </div>
                        <div className="dollar">
                            <Dollar/>
                        </div>
                    </div>
                    <h2>We turn ESports data into live financial assessment</h2>
                    <p>
                        Project E is the provider of real-time statistics for the ESports. For this purpose, we collect data from competitions matches using automatic algorithms and use advanced predictive fundamental analytics to identify the potential of ESports players.
                        <br/>
                        <br/>
                        Providing data to various members of the ecosystem, Project E helps ESports organizations automate transfer deals and find future stars for their teams, attracting millions of ESports enthusiasts.
                    </p>
                </div>
                <div className="maps-container">
                    <div className="adress">
                        <div className="logo">
                            <a href="/">Project E</a>
                        </div>
                        <p>Nicosia - Republic of Cyprus</p>
                        <a className="mail" href="mailto:hello@projecte.space">hello@projecte.space</a>
                    </div>
                </div>
            </div>
            <Footer/>
        </>
    );
}

export default About